import { isEmpty } from 'lodash';
import moment from 'moment';
import { vitalDataStatus } from './chartConfigVitalData';

const checkFormat = 'YYYY-MM-DD';

/**
 * @description main function
 * @param {*} data
 */
const splitDataToCharts = (data: any, second: any = false) => {
  // check if second is true and data contains values for second chart line
  if (second && !data.some((item: any) => item.y2)) return null;

  // ensure that all data is sorted from newest to old
  data.sort((a: any, b: any) => {
    const aZ: any = new Date(b.validOnDateAt);
    const bZ: any = new Date(a.validOnDateAt);
    return aZ - bZ;
  });
  const existDates = getDates(data);

  /**
   * @description get max values for each submit day in new array (this is lineSeriesData)
   */
  const checkParam = second ? 'y2' : 'y';
  let maxValues =
    existDates &&
    existDates.map((item: any) => getMaxValueFromDate(data, checkParam, item)); // disable latest max if u want

  /**
   * @description get all values that are not maxValues (this is markSeriesData)
   */
  let allValuesWithoutMax = data.filter((item: any) => {
    const isNotIsMaxValues =
      maxValues &&
      !maxValues.some((i: any) => i.validOnDateAt === item.validOnDateAt);

    return (
      isNotIsMaxValues && {
        ...item,
        time: moment(item.validOnDateAt).format('HH:mm')
      }
    );
  });

  /**
   * concat two charts
   */
  if (second) {
    return {
      lineSeries2: maxValues,
      markSeries2: allValuesWithoutMax
    };
  }

  return {
    lineSeries: maxValues,
    markSeries: allValuesWithoutMax
  };
};

/**
 * getDates: get each day where an entry has been submited (as list without double dates)
 **/
const getDates = (data: any) => {
  let existDates: any = [];
  data &&
    data.forEach((item: any) => {
      const day = moment(item.validOnDateAt).format(checkFormat);
      if (!existDates.includes(day)) {
        existDates.push(day);
      }
    });

  return existDates;
};

/**
 * get max value from date perhaps: feature bug first or last highest value per day
 **/
const getMaxValueFromDate = (data: any, prop: any, date: any) => {
  let max: any;
  data.forEach((item: any) => {
    const itemDate = moment(item.validOnDateAt).format(checkFormat);
    const isSameDay = moment(date).isSame(itemDate);
    const noMax: any = max === undefined;
    if (isSameDay && (noMax || parseInt(item[prop]) > parseInt(max[prop]))) {
      max = item;
    }
  });
  return max;
};

const getHighestValue = async ({ observationsData, type, item }: any) => {
  const data = observationsData;
  if (isEmpty(data)) return { status: null, statusDay: null };
  const isVital = type === 'vital';
  let status = undefined;

  const formatedDay = (itemData: any) => {
    return moment(itemData.validOnDateAt).format('YYYY-MM-DD ');
  };

  const lastSubmitDay = data && formatedDay(data[0]);

  const latestEntry =
    data && data.filter((item: any) => lastSubmitDay === formatedDay(item)); // patient submitions from the last submited day
  //const latestEntry = data && data.filter(async item => moment(lastSubmitDay).isSame(await formatedDay(item)));

  if (!isEmpty(latestEntry) && latestEntry.length === 1) {
    status = isVital
      ? vitalDataStatus(latestEntry[0], item)
      : latestEntry[0].value;
  } else {
    status = isVital
      ? vitalDataStatus(latestEntry, item)
      : Math.max(...latestEntry.map((item: any) => item.value));
  }

  return { status, statusDay: latestEntry?.[0]?.validOnDateAt };
};

export { splitDataToCharts, getHighestValue };
