import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
// import i18n from 'i18next';
import CasesOverviewTable from '../../components/Tables/CasesTable/CasesTable';
import { Layout } from 'antd';
import CasesArchiveTable from '../../components/Tables/CasesArchiveTable/CasesArchiveTable';
import CasesHeader from '../../components/CasesHeader/CasesHeader';
import { ICasesArchiveOverview } from '../../models/pages/ICasesArchiveOverview';
import './../../scss/pages/CasesOverview.scss';

const { Content } = Layout;

const CasesArchivOverview: FC<ICasesArchiveOverview> = ({
  patientCareStore,
  authStore,
  organisationManagement,
  medicationTherapyStore
}): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const orgConfig = organisationManagement.getOrgConfig;
  const userAttributes = authStore.getUserAttributes;
  const { fetchArchivedCases } = patientCareStore;
  const casesArchiveLoaded: boolean = patientCareStore.casesArchiveLoaded;
  useEffect(() => {
    !casesArchiveLoaded && getCases();
    return () => {
      patientCareStore.setCasesArchiveLoaded(false);
      patientCareStore.setCasesArchive([]);
      // FIX ME use setter
      medicationTherapyStore.alarmtTresholdReview = null;
    };
  }, [patientCareStore, medicationTherapyStore]);

  const getCases = async () => {
    setLoaded(!loaded);
    await fetchArchivedCases();
  };

  /**
   *
   * @description search value
   * @param e
   */
  const search = (searchFilter: any): any => {
    setSearchFilter(searchFilter);
    fetchArchivedCases(searchFilter);
  };

  const onchangeFilter = (e: any): void => {
    console.log(e.target.value);
    setSearchFilter(e.target.value);
  };

  /**
   *
   */
  const changeFilterTreatmentState = (filter: string) => {
    patientCareStore.setFilterTreatmentState(filter);
    setSearchFilter('');
    fetchArchivedCases('');
  };

  const chosenFilterOption = patientCareStore.getCasesArchiveFilter;

  return (
    <Layout style={{ padding: '0px 0px 0px 0px', margin: 0, marginBottom: 14 }}>
      <Content
        className=" about-wrapper "
        style={{
          padding: '0px 0px',
          margin: 0,
          flexDirection: 'column'
        }}
      >
        <CasesHeader
          search={search}
          onchangeFilter={onchangeFilter}
          searchFilter={searchFilter}
          invitePatient={() => {}}
          userAttributes={userAttributes}
          orgConfig={orgConfig}
          isArchiv={true}
          chosenFilterOption={chosenFilterOption}
          changeFilterTreatmentState={changeFilterTreatmentState}
          casesLoaded={casesArchiveLoaded}
        />
        <CasesArchiveTable />
      </Content>
    </Layout>
  );
};

export default inject(
  'patientCareStore',
  'authStore',
  'organisationManagement',
  'medicationTherapyStore'
)(observer(CasesArchivOverview));
