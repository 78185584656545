import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { IImprint } from '../../models/pages/IImprint';

const { Title, Text } = Typography;

const Imprint: FC<IImprint> = (): JSX.Element => {
  return (
    <Row justify="center">
      <Col xs={23} sm={23} xl={22} className="about-wrapper">
        <Title level={2} className="text-center ">
          Impressum
        </Title>

        <Col className="content-wrapper dtb-long-text dtb-imprint-text-layout">
          <div className="avoid-break">
            <Text strong>
              DTB Gesellschaft für digitale Therapiebegleitung mbH
              <br />
            </Text>
            <Text>
              Otto-Schott-Straße 15 <br />
              07745 Jena <br /> <br />
            </Text>
          </div>
          <div className="avoid-break">
            <Text strong>
              Geschäftsführer: <br />
            </Text>
            <Text>
              Ingmar Wegner <br />
              <br />
              Telefon: 03641 62840284 <br />
              Telefax: 03641 628400320 <br />
              E-Mail:{' '}
              <a href="mailto:info@digitale-therapiebegleitung.de">
                info@digitale-therapiebegleitung.de
              </a>{' '}
              <br />
              <br />
            </Text>
          </div>
          <div className="avoid-break">
            <Text strong>
              Steuer &amp; Registereintrag <br />
            </Text>
            <Text>
              Ust-IDNr. DE 340 985 477 <br />
              Steuer-Nr. 162/107/06880 <br />
              HRB 508202 <br />
              Amtsgericht Jena <br />
              <br />
            </Text>
          </div>
          <div className="avoid-break">
            <Text strong>
              Compliance-Officer: <br />
            </Text>
            <Text>
              Frank Sürmann <br />
              Telefon:{' '}
              <a href="tel: 49123456789" rel="noopener noreferrer">
                0621 1250860
              </a>
              <br />
              E-Mail:{' '}
              <a href="mailto:compliance@convales.de">
                compliance@convales.de
              </a>{' '}
              <br />
              <br />
            </Text>
          </div>
          <div className="avoid-break">
            <Text strong>
              Zweckbestimmung
              <br />
            </Text>
            <Text>
              Der Tino DTB ist ein digitales Medizinprodukt zur aktiven
              Unterstützung des Therapiemanagements von Patienten, die in einem
              onkologischen Versorgungsprozess medikamentös therapiert und durch
              Ärzte und medizinisches Fachpersonal begleitet werden.
              <br />
              <br />
            </Text>
            <Text strong>
              Es dient folgenden Zwecken:
              <br />
            </Text>
            <Text>
              Der Tino DTB verbessert das Therapiemanagement durch:
              <br />
              <ul>
                <li>
                  einnahmezeitpunkt- und medikationsbezogene Therapiepläne,
                </li>
                <li>
                  therapieindividuelle Erinnerungsfunktionen zur
                  Medikationseinnahme,
                </li>
                <li>
                  therapieindividuelle Anzeige von Nebenwirkungen, Vitalwerten,
                </li>
                <li>
                  Einnahmehinweisen und wichtige Informationen zur Medikation,{' '}
                </li>
                <li>
                  die Dokumentation von Medikationseinnahmen, Nebenwirkungen und
                  Vitalwerten.
                </li>
              </ul>
              <br />
            </Text>
          </div>
        </Col>
      </Col>
    </Row>
  );
};

export default Imprint;
