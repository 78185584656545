import React from 'react';
import { Tooltip, Typography, Badge } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { TherapyCycleDay, TherapyState } from '../../services/therapyServices';
import CasesAvatar from '../../components/CasesAvatar/CasesAvatar';
import CasesSideEffectsThresholdTooltip from '../../components/CasesSideEffectsThresholdTooltip/CasesSideEffectsThresholdTooltip';
import CasesMedikationInTakeTooltip from '../../components/CasesMedikationInTakeTooltip/CasesMedikationInTakeTooltip';

const { Text } = Typography;

const casesTableTemplate = (t: Function) => {
  const isPast24Hours = (date: any) => {
    // retreview today minus 24 hours
    const last24Hours = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    // if exactDateTimeAt is before the 24hours show warning
    return !!date && moment(date).isBefore(last24Hours);
  };

  const columns: any = [
    {
      title: t(`casestable:patient`),
      fixed: 'left',
      dataIndex: '',
      // responsive: ['sm'],
      width: 200,
      sorter: (a: any, b: any) => {
        console.log(a.patientLastName);
        console.log(b.patientLastName);
        const patientName = (i: any) =>
          !isEmpty(i.patientLastName) ? i.patientLastName : '';
        return patientName(a).localeCompare(patientName(b));
      },
      render: (entry: any) => {
        return <CasesAvatar entry={entry} />;
      }
    },
    {
      title: t(`casestable:medInTake`),
      key: 'adherence',
      dataIndex: 'adherence',
      align: 'center',
      width: 170,
      minWidth: 220,
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (isEmpty(a) || isEmpty(b)) return null;
        // do sorting according current adherence status or warning info by using numbers
        // 0 (low) === default different status like completed..
        // 1  === no adherence has been submit (in general or within the last 24 hours )
        // 2 (height) === stop taking medicine (red warning color)
        const checkItem = ({ item: { adherence } }: any) => {
          let state = 1; // if not data exists
          if (!isEmpty(adherence) && adherence[0]) {
            state = adherence[0].status === 'stopped' ? 2 : 0;
            state =
              state !== 2 && isPast24Hours(adherence[0].exactDateTimeAt)
                ? 1
                : state;
          }
          return state;
        };
        return checkItem({ item: a }) - checkItem({ item: b });
      },
      render: (adherence: any) => {
        return (
          <CasesMedikationInTakeTooltip
            adherence={adherence}
            isPast24Hours={isPast24Hours}
          />
        );
      }
    },
    {
      title: t('casestable:therapyPlan'),
      width: 400,
      dataIndex: 'currentTherapyPlan',
      key: 'currentTherapyPlan',
      className: 'col-therapy-title',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any) => {
        const title = (i: any) =>
          !isEmpty(i.currentTherapyPlan) ? i.currentTherapyPlan.title : 'Zzzzz';
        return title(a).localeCompare(title(b));
      },
      render: function showCurrentPlan(currentTherapyPlan: any) {
        const { title } = !isEmpty(currentTherapyPlan) && currentTherapyPlan;
        return (
          <Tooltip placement="bottomLeft" title={title}>
            <Text>{title}</Text>
          </Tooltip>
        );
      }
    },
    {
      title: t('casestable:therapyCycle'),
      dataIndex: 'currentTherapyPlan',
      key: 'currentTherapyPlan',
      align: 'center',
      width: 140,
      render: (currentTherapyPlan: any) => {
        return <Text>{currentTherapyPlan?.cycle}</Text>;
      }
    },
    {
      title: t('casestable:day'),
      dataIndex: 'currentTherapyPlan',
      key: 'currentTherapyPlan',
      width: 60,
      render: (currentTherapyPlan: any) => {
        let { startAt, endAt } =
          !isEmpty(currentTherapyPlan) && currentTherapyPlan;
        let diffDays =
          !isEmpty(currentTherapyPlan) && TherapyCycleDay({ startAt });

        const state = TherapyState({ startAt, endAt });
        diffDays = diffDays && state !== 'completed' ? diffDays : false;
        return (
          !isEmpty(currentTherapyPlan) && (
            <Text>
              {diffDays ? diffDays : t(`PatientCare.therapy.${state}`)}
            </Text>
          )
        );
      }
    },
    {
      title: t('casestable:sideEffects'),
      align: 'center',
      width: 170,
      minWidth: 220,
      sorter: (a: any, b: any) => {
        return (
          a?.alarmThresholdReview?.length - b?.alarmThresholdReview?.length
        );
      },
      sortDirections: ['descend', 'ascend'],
      render: (entry: any) => {
        return (
          <CasesSideEffectsThresholdTooltip
            alarmThresholdReview={entry.alarmThresholdReview}
            len={entry?.sideEffects?.length}
          />
        );
      }
    },
    {
      title: t('casestable:chat'),
      key: 'notificationsCount',
      dataIndex: 'notificationsCount',
      align: 'center',
      width: 100,
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => {
        const aCounter = a.notificationsCount || 0;
        const bCounter = b.notificationsCount || 0;
        return aCounter - bCounter;
      },
      render: (notificationsCount: number) => {
        return (
          <>
            {notificationsCount > 0 && (
              <div
                style={{
                  width: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 40,
                  margin: 'auto'
                }}
              >
                <Badge
                  count={notificationsCount}
                  style={{ backgroundColor: '#F76A6A' }}
                />
              </div>
            )}
          </>
        );
      }
    }
  ];

  return columns;
};

export default casesTableTemplate;
