import styles from '../../scss/variables.scss';

export type ITrafficLightThresholdColors = {
  0: string;
  1: string;
  2: string;
  3: string;
  RED: string;
};

const trafficLightThresholdColors = (
  key: string | number = 'empty'
): string => {
  const trafficLightThresholdColors: ITrafficLightThresholdColors | any = {
    0: styles.yellowGreen,
    1: styles.yellowUpdatedProgress,
    2: styles.yellowUpdatedProgress,
    3: styles.rose,
    RED: styles.rose,
    empty: '',
    '': ''
  };

  return trafficLightThresholdColors[key];
};

export default trafficLightThresholdColors;
