import * as React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './LanguageSwitch.scss';
import { IOptionsSelectLanguage } from '../../models/components/IOptionsSelectLanguage';

const LanguageSwitch = () => {
  const { Option } = Select;
  const { i18n } = useTranslation();
  const options: IOptionsSelectLanguage = [
    {
      value: 'de'
    },
    {
      value: 'en'
    }
  ];

  const changeLanguage = (lng: string) => {
    document.documentElement.lang = lng;
    i18n.changeLanguage(lng);
  };

  return (
    <Select
      defaultValue={localStorage.getItem('i18nextLng')}
      className="language-menu"
      onChange={changeLanguage}
    >
      {options.map((item) => (
        <Option className="language-item" key={item.value} value={item.value}>
          <p
            style={{
              fontSize: '1.8em',
              lineHeight: 1,
              maxHeight: '20px',
              display: 'inline',
              textAlign: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
            className="flag-image"
          >
            {item.value === 'de' ? <>🇩🇪</> : <>🇬🇧</>}
          </p>
        </Option>
      ))}
    </Select>
  );
};
export default LanguageSwitch;
