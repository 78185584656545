import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Divider } from 'antd';
import NewMessageList from '../NewMessageList/NewMessageList';
import '../../scss/components/NotificationDrawer.scss';

type Props = {
  toogleCanvasMenu: Function;
  t: Function;
};

type State = {
  open: boolean;
  notificationCountOld: number;
};

@inject()
@observer
class NotificationDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      notificationCountOld: 0
    };
  }

  joinCommunity = (communityId: string) => {};

  leaveCommunity = (communityId: string) => {};

  open = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  closeCanvasMenu = () => {
    const foo: any = document.getElementById('offcanvas');
    const bgcanvas: any = document.getElementById('bgcanvas');
    foo.classList.remove('open-canvas');
    bgcanvas.classList.remove('bgcanvasshow');
  };

  playAudio = () => {
    const audit: any = document.getElementById('audit');
    audit.muted = false;
    audit.play();
  };

  componentDidUpdate() {
    const { open } = this.state;
    const unreadMessages = 0; // getUnreadMessages(myBuddies, myGroups);
    const unreadGroupMessages = 0; // myGroups && getUnreadGroupMessages(myGroups);

    const notificationCount = 0; /* unreadMessages.concat(
      myCommunitiesRequestReceived,
      myBuddiesRequestReceived,
      unreadGroupMessages,
    ).length;*/

    if (notificationCount > this.state.notificationCountOld) {
      // here we can play sound this.playAudio();
    }
    if (
      notificationCount > this.state.notificationCountOld ||
      notificationCount < this.state.notificationCountOld
    ) {
      this.setState({ notificationCountOld: notificationCount });
    }
  }
  render() {
    const { toogleCanvasMenu, t } = this.props;
    const unreadMessages = 0;
    const notificationCount = 0;
    return (
      <>
        <div className="offcanvas" id="offcanvas">
          {notificationCount > 0 ? (
            <>
              {1 > 0 && (
                <>
                  <NewMessageList
                    unreadMessages={unreadMessages}
                    toogleCanvasMenu={toogleCanvasMenu}
                  />
                  <Divider />
                </>
              )}
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <h3
                style={{
                  color: '#fff'
                }}
              >
                {t('core:noNotifications')}
              </h3>
            </div>
          )}
        </div>
        <div
          className="bgcanvas"
          id="bgcanvas"
          onClick={this.closeCanvasMenu}
        ></div>
      </>
    );
  }
}

export default NotificationDrawer;
