import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { medicationTherapyClient } from '../../axios';
import {
  generateSideEffectsSplitCharts,
  transformToSideChartStructure
} from './config/chartConfigSideEffects';
import {
  generateVitalSplitCharts,
  transformToVitalChartStructure
} from './config/chartConfigVitalData';
import { getHighestValue } from './config/global';

export class MedicationTherapyStore {
  /**
   * @description medplan and medplans
   */
  @observable
  public currentMedplan: any = null;
  @observable
  public currentMedplanFixed: any = null;
  @observable
  public therapyPlans: any = [];

  @observable
  public currentMedication: any = null;
  @observable
  public currentMedplanLoaded = false;
  /**
   * @description side effects settings
   */
  @observable
  public alarmtTresholdReview = null; // side effect review

  @observable
  public sideEffectsSettings: any = null;
  @observable
  public sideEffectsSettingsLoaded: boolean = false;

  @observable
  public vitalDataSettings: any = null;
  @observable
  public vitalDataSettingsLoaded: boolean = false;

  // clean refactore

  @observable
  public medplans: any = [];
  @observable
  public medication: any = [];
  @observable
  public medicationOverflowLoaded = false;
  // split medication array into 4 tables, "THERAPY", "SUPPORTIVE", "REQUIRED", "OTHER"
  @observable
  public medicationTherapy: any = [];
  @observable
  public medicationSupportive: any = [];
  @observable
  public medicationRequired: any = [];
  @observable
  public medicationOther: any = [];

  @observable
  public sideEffects: any = [];
  @observable
  public vitalData: any = [];

  @observable
  public notTakenMedLoaded: boolean = false;
  @observable
  public notTakenMed: any = null;

  // new part observations
  @observable
  private observations = new Map([
    //['f91cbb77-99dc-4cc4-99b0-e6373a8adde9', []]
  ]);

  @observable
  private observations2 = {};

  @observable
  private visibleObservations = [];

  constructor() {
    makeObservable(this);
  }

  /**
   * @description getter setter ui visible observations
   */
  @computed
  get getVisibleObservations() {
    return this.visibleObservations;
  }

  @action
  setVisibleObservations(entry: any): void {
    this.visibleObservations = entry;
  }

  /**
   * @description getter setter current medication plan
   */
  @computed
  get getCurrentMedplan() {
    return this.currentMedplan;
  }

  @action
  setCurrentMedplan(entry: any): void {
    this.currentMedplan = entry;
  }

  /**
   * @description getter setter medication overview
   */
  @computed
  get getMedicationOverview() {
    return this.medication;
  }

  @action
  setMedicationOverview(entry: any): void {
    this.medication = entry;
  }

  /**
   * @description needed in sidebar change chosen cuurrent medplan and load details
   * (is not really change medplan is only which medplan details is showing)
   * @param id
   */
  @action
  public changeCurrentMedPlan = (id: string) => {
    this.currentMedplanLoaded = true;
    const object = this.therapyPlans.find((obj: any) => obj.id === id);
    this.currentMedplan = object;
    this.currentMedplanLoaded = false;
    /**
     * @description FIX ME todo load details after change medplan
     */
  };

  /**
   * @description getter setter none taken meds
   */
  get getAlarmtTresholdReviewDay() {
    return this.alarmtTresholdReview;
  }

  @action
  setAlarmtTresholdReviewDay(entries: any): void {
    this.alarmtTresholdReview = entries;
  }

  /**
   * @description getter setter none taken meds
   */
  @computed
  get getNoneTakenMeds() {
    return this.notTakenMed;
  }

  @action
  setNoneTakenMeds(entries: any): void {
    this.notTakenMed = entries;
  }

  /**
   * @description getter setter medications plans
   */
  @computed
  get getTherapyPlansList() {
    return this.therapyPlans;
  }

  @action
  setTherapyPlansList(entries: any[] | null): void {
    this.therapyPlans = entries;
  }

  /**
   * @description getter setter sideeffect settings + loader
   */
  @computed
  get getSideEffectSettings() {
    return this.sideEffectsSettings;
  }

  @action
  setSideEffectSettings(entries: any) {
    this.sideEffectsSettings = entries;
  }

  @computed
  get getSideEffectSettingsLoaded() {
    return this.sideEffectsSettingsLoaded;
  }

  @action
  setSideEffectSettingsLoaded(option: boolean) {
    this.sideEffectsSettingsLoaded = option;
  }

  @computed
  get getVitalDataSettings() {
    return this.vitalDataSettings;
  }

  @action
  setVitalDataSettings(entries: any) {
    this.vitalDataSettings = entries;
  }

  @computed
  get getVitalDataSettingsLoaded() {
    return this.vitalDataSettingsLoaded;
  }

  @action
  setVitalDataSettingsLoaded(option: boolean) {
    this.vitalDataSettingsLoaded = option;
  }

  /**
   * @description getter setter sideeffects observations
   */
  @computed
  get getSideEffectsObservations() {
    return null;
  }

  @action
  setSideEffectsObservations(entries: any): void {
    this.observations = entries;
  }

  @action clearObservations(): void {
    this.observations.clear();
  }

  /**
   * @description todo clean up
   */

  @computed
  get getObservations2() {
    return this.observations2;
  }

  @computed
  get getObservations() {
    // setter this.observations.set('a', 97)
    // delete this.observations..delete('b');
    // size  this.observations.size()

    return this.observations;
  }
  @action.bound async getPlanData(
    patientId: string,
    medPlanId: string
    //sideEffect: any
  ) {
    await this.fetchAlarmtTresholdReviewDay(medPlanId, patientId);
    // await this.loadSideEffectsObservations(patientId, sideEffect);
  }

  @action
  public setObservations(id: string, list = []) {
    // setter this.observations.set('a', 97)
    // delete this.observations..delete('b');
    // size  this.observations.size()
    return this.observations.set(id, list);
  }

  @action
  public loadSideEffectsObservations = async (
    patientId: string,
    sideEffect: any,
    addedData: any = { take: 50, skip: 0, gt: 0 }
  ) => {
    if (this.observations2.hasOwnProperty(sideEffect.sideEffectId)) {
      console.log('exist');
    } else {
      console.log('not exist');
      const res = await this.fetchSideEffectData(patientId, sideEffect, {
        take: 50,
        skip: 0,
        gt: 0
      });
      console.log(JSON.stringify(res));
      console.log(res);
      console.log(res?.data);
      console.log(res?.data?.[0]);
      this.observations2 = {
        ...this.observations2,
        [sideEffect.sideEffectId]: res?.length > 0 ? res?.data?.[0] : res
      };

      console.log(this.observations2);
    }
    console.log(sideEffect.sideEffectId);
    //console.log(JSON.stringify(this.observations));
    //console.log(this.observations.get(sideEffect.sideEffectId));
    if (this.observations.has(sideEffect.sideEffectId)) {
      //console.log('already exists in map ');
      return this.observations.get(sideEffect.sideEffectId);
    } else {
      //    this.observations.set(sideEffect.sideEffectId, []);
      const res = await this.fetchSideEffectData(patientId, sideEffect, {
        take: 50,
        skip: 0,
        gt: 0
      });
      console.log(res);
      const convertToMapping = res.map((item: any) => {
        const ma = res.map((item: any) => {
          return { x: item.validOnDateAt, y: item.value };
        });
        return {
          status: item.value,
          validOnDateAt: item.validOnDateAt,
          dataList: res,
          lineSeries: [
            ...ma
            /*
            {
              x: '2022-09-09T10:00:00.000Z',
              y: 1
            },
            { x: '2022-09-04T10:00:00.000Z', y: 1 },
            {
              x: '2022-09-04T10:00:00.000Z',
              y: 1,
              status: 'RED',
              value: 1,
              validOnDateAt: '2022-09-04T10:00:00.000Z',
              createdAt: '2022-09-04T12:25:24.504Z'
            }
            */
          ],
          markSeries: [{ x: '2022-09-13', y: 1 }],
          min: 1,
          max: 5,
          step: 1,
          isSideEffect: true,
          yUnit: 1
        };
      });

      console.log('convertToMapping');
      console.log(convertToMapping);
      this.observations.set(sideEffect.sideEffectId, convertToMapping[0]);
      return res[0];
    }

    // setter this.observations.set('a', 97)
    // delete this.observations..delete('b');
    // size  this.observations.size()
  };

  /**
   * @description retrieve the current ongoing therapy plan(s) also called medication plan
   * this can be one or more plans
   * used: in onko table and in the detail view therapy box
   * @param patientId
   * @returns
   */
  @action
  public fetchCurrentMedPlan = async (patientId: string) => {
    try {
      const today = moment(new Date()).format('YYYY-MM-DD');
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration?exactDate=${today}&patientId=${patientId}&relations[]=warnings`
      );
      if (res.data[0]?.length > 0) {
        this.setCurrentMedplan(res.data[0][0]);
        this.setTherapyPlansList(res.data[0]);
        this.medplans = res.data[0];
        //console.log(res.data[0][0]);
        this.currentMedplanFixed = res.data[0][0];
      } else {
        this.setCurrentMedplan(null);
      }

      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @description retrieve the current ongoing therapy plan(s) also called medication plan
   * this can be one or more plans
   * used: in onko table and in the detail view therapy box
   */
  @action
  public getArchivedMedPlans = async (patientId: string, skip: number = 0) => {
    if (isEmpty(patientId)) return null;
    try {
      const take = 10;
      let yesterday: any = moment(new Date()).format('YYYY-MM-DD');
      yesterday = moment(yesterday).subtract(1, 'days');
      yesterday = moment(yesterday).format('YYYY-MM-DD');
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration?ltDate=${yesterday}&patientId=${patientId}&take=${take}&skip=${skip}order[endAt]=DESC`
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @description get (all) plans from a certian patient
   */
  @action
  public fetchTherapyPlans = async ({
    patientId,
    skip = 0
  }: {
    patientId: string;
    skip: number;
  }) => {
    try {
      const take = 15;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration?patientId=${patientId}&take=${take}&skip=${skip}&order[startAt]=DESC`
      );
      this.therapyPlans = res.data[0];

      //console.log(res);
      this.medplans = res.data[0];
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @description get a certain therapy plan
   */
  @action
  public getTherapyPlan = async (planId: string) => {
    if (isEmpty(planId)) return null;
    try {
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration/${planId}`
      );
      this.therapyPlans = res.data[0];
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @description medipolis medication for a certain therapy plan
   * this main informations is stored in the medication end api, but in
   * ohter end points (below)
   */
  @action
  public getMedication = async (planId: string) => {
    //console.log(planId);
    if (isEmpty(planId)) return null;
    this.medicationOverflowLoaded = false;
    this.currentMedication = null;
    try {
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration/${planId}/medication/overview`
      );

      // medication all
      if (res.data[1] > 0) {
        //this.currentMedication = res.data[0];
        //this.setCurrentMedplan(res.data[0][0]);
      }
      //console.log(res.data[0]);

      this.setMedicationOverview(res.data[0]);
      // this.medication = res.data[0];
      // this.medicationOverflowLoaded = true;

      return res;
      /*
      this.medicationSupportive = this.medication.filter(
        (item: any) => item.medicationType?.toUpperCase() === 'SUPPORTIVE' // console.log(JSON.stringify(item)) //Therapy
      );
      this.medicationRequired = this.medication.filter(
        (item: any) => item.medicationType?.toUpperCase() === 'REQUIRED' // console.log(JSON.stringify(item)) //Therapy
      );
      this.medicationOther = this.medication.filter(
        (item: any) => item.medicationType?.toUpperCase() === 'OTHER' // console.log(JSON.stringify(item)) //Therapy
      );*/
    } catch (e) {
      return false;
    }
  };

  /**
   * @description get side effects settings
   */
  @action
  public getSideEffectsOptions = async () => {
    try {
      const res: any = await medicationTherapyClient.get<any>(
        `/side-effect?take=50`
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @description get side effects
   * @param patientId
   * @param medPlanId
   * @returns
   */
  @action
  public fetchSideEffectsSettings = async (
    patientId: string,
    medPlanId: string
  ) => {
    console.log(patientId);
    console.log(medPlanId);
    if (isEmpty(medPlanId)) return [];
    // alert('y')
    try {
      this.sideEffectsSettingsLoaded = false;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/side-effect-setting?patientId=${patientId}`
      );
      /*
      if (!isEmpty(res.data)) {
        // TODO: remove true check when enum-value is fully implemented
        const isOn = (item: any) => item === true || item === 'ON';
        const filterSetting = (list: any) =>
          list.filter((item: any) => {
            // filter for items that are switch on
            return isOn(item.hcpEnabledState) || isOn(item.patientEnabledState);
          });
        // if settings are an object transform
        const objectItem = (data: any) =>
          isOn(data.hcpEnabledState) || isOn(data.patientEnabledState)
            ? [...data]
            : [];
        res.data =
          res.data.length > 1 ? filterSetting(res.data) : objectItem(res.data);
      }
    */ this.sideEffectsSettingsLoaded = true;
      console.log(res);
      this.sideEffectsSettings = res.data;
      this.sideEffects = res.data;
      //this.sideEffectsSettings = {
      //  ...this.sideEffectsSettings
      //viewData: [{ x: 1, y: '2022-08-05' }]
      //};

      console.log(res.data);
      return this.sideEffectsSettings;
    } catch (e) {
      return e;
    }
  };

  /**
   *
   * @description new api endpoint 12.09.2022 after refactoring
   * @param medPlanId
   * @param patientId
   * @returns
   */
  @action
  public fetchAlarmtTresholdReviewDay = async (
    medPlanId: string,
    patientId: string
  ) => {
    try {
      this.sideEffectsSettingsLoaded = false;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/side-effect-observation/alarm-review?patientId=${patientId}`
      );

      let lol = res.data.map((item: any) => {
        return {
          ...item,
          title: item.name,
          values: item.values.length > 0 ? item?.values : ['-1'] // if no data set set type to empty color
        };
      });
      lol = lol?.sort((a: any, b: any) => b.values[0] - a.values[0]);

      const sortedData: any = lol.sort((a: any, b: any) => {
        const dataA: any = new Date(a?.reviewDateAt);
        const dataB: any = new Date(b?.reviewDateAt);
        const res: any = dataB - dataA;
        return res;
      });

      this.setAlarmtTresholdReviewDay(sortedData);

      return lol;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @description post side en/disbaled status
   * @param patientId
   * @param medPlanId
   * @param submitValues
   * @returns
   */
  @action
  public postSideEffectsStatus = async (
    patientId: string,
    medPlanId: string,
    submitValues: any
  ) => {
    console.log(medPlanId);
    if (isEmpty(medPlanId) || isEmpty(submitValues)) return null;
    let { sideEffectId, enabled } = submitValues;
    enabled = enabled; // 'OFF'// enabled === 'OFF' ? false : enabled;
    try {
      const res: any = await medicationTherapyClient.post<any>(
        `/medication-plan/${medPlanId}/side-effect-setting`,
        {
          medicationPlanId: medPlanId,
          sideEffectId,
          enabled,
          patientId
        }
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @description post threshold data
   * @param medPlanId
   * @param submitValues
   * @param patientId
   * @returns
   */
  @action
  public postSideEffectsThreshold = async (
    patientId: string,
    submitValues: any
  ) => {
    if (isEmpty(submitValues)) return null;
    const { alarmThreshold, sideEffectId, medicationPlanId } = submitValues;
    const { max } = alarmThreshold[0];
    try {
      const res: any = await medicationTherapyClient.post<any>(
        `/medication-plan/${medicationPlanId}/side-effect-setting/set-alarm-threshold`,
        {
          medicationPlanId: medicationPlanId,
          sideEffectId,
          patientId,
          max,
          color: 'RED'
        }
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @description get observation data for a certain side effect
   * @param patientId
   * @param sideEffect
   * @param addedData
   * @returns
   */
  @action
  public fetchSideEffectData = async (
    patientId: any,
    sideEffect: any,
    addedData: any = { take: 50, skip: 0, gt: 0 }
  ) => {
    console.log(sideEffect);

    // console.log(patientId);

    const { sideEffectId, medicationPlanId: medPlanId } = sideEffect;
    if (isEmpty(patientId) || isEmpty(medPlanId) || isEmpty(sideEffectId))
      return null;
    // console.log('ist da richtig');
    try {
      let { take, skip, gt } = addedData;
      take = take || 150;
      skip = skip || 0;

      const { startAt, endAt, id } = this.currentMedplan;

      gt = !isEmpty(startAt)
        ? moment(new Date(startAt)).format('YYYY-MM-DD')
        : '';
      let lt: any = moment(endAt).format('YYYY-MM-DD');
      lt = moment(lt).add(1, 'days');
      lt = moment(lt).format('YYYY-MM-DD');

      console.log(JSON.stringify(this.currentMedplan));

      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${id}/side-effect-observation?patientId=${patientId}&sideEffectId=${sideEffectId}&take=150&skip=${skip}&ltDate=${lt}&gtDate=${gt}&relations[]=sideEffect&order[createdAt]=DESC`
      );

      return res.data[0];
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @param selectedCase
   * @param data
   * @param type
   * @returns
   */
  @action
  public getObservationData = async (
    selectedCase: any,
    data: any,
    type: string
  ) => {
    // this function is the expand the standard case infos with vital data,
    // used: used in case detail view for onko/medipolis user
    //alert('yo ');
    const { patientId, currentTherapyPlan } = selectedCase;
    if (isEmpty(currentTherapyPlan)) return { ...selectedCase };
    const { id: medPlanId } =
      !isEmpty(currentTherapyPlan) && currentTherapyPlan;
    const callData = { medPlanId, patientId }; // do this to avoid linebreaks
    let vitalData = await this.enabledSettings({
      callData,
      type: 'vital',
      data
    });
    // first vitalData is a list of all parameters which are enabled
    // second: until sttings api is extanded, call observationsdata to get status and last submition

    vitalData = await Promise.all(
      vitalData.map(async (item: any) => {
        const data =
          type === 'vital'
            ? await this.fetchVitalObservations(patientId, item, {}, medPlanId)
            : await this.fetchSideEffectData(patientId, item);

        let viewData: any = [];
        let chartData: any = [];
        if (data.hasOwnProperty('data') && data.data[0].length > 0) {
          // transform mapper
          viewData =
            type === 'vital'
              ? await transformToVitalChartStructure(data.data[0], item)
              : await transformToSideChartStructure(data, item);

          chartData =
            type === 'vital'
              ? await generateVitalSplitCharts(item, viewData)
              : await generateSideEffectsSplitCharts(data, viewData, item);
        }
        const { status, statusDay }: any =
          !isEmpty(data) &&
          (await getHighestValue({
            observationsData: type === 'vital' ? data.data[0] : data,
            type: type,
            item
          }));

        const mapperA = {
          observationsData: data,
          viewData: viewData,
          chartData: chartData,
          status,
          statusDay
        };

        item = {
          ...item,
          ...mapperA
        };

        return item;
      })
    );

    if (type === 'vital') {
      this.vitalData = vitalData;
    } else {
      this.sideEffects = vitalData;
    }
  };

  /**
   *
   * @param param0
   * @returns
   */
  @action
  private enabledSettings = async ({ callData, type, data }: any) => {
    let settings = data;
    //type === 'sideEffect' ? this.sideEffectsSettings : this.vitalDataSettings;

    if (!isEmpty(settings)) {
      // TODO: remove true check when enum-value is fully implemented
      const isOn = (item: any) => item === true || item === 'ON';
      const filterSetting = (list: any) =>
        list.filter((item: any) => {
          // filter for items that are switch on
          return isOn(item.hcpEnabledState) || isOn(item.patientEnabledState);
        });
      // if settings are an object transform
      const objectItem = (data: any) =>
        isOn(data.hcpEnabledState) || isOn(data.patientEnabledState)
          ? [...data]
          : [];
      settings =
        settings.length > 1 ? filterSetting(settings) : objectItem(settings);
    }
    return settings;
  };

  /**
   * @description get all usable vital parameters
   */
  @action
  public fetchVitalDataOptions = async () => {
    try {
      const res: any = await medicationTherapyClient.get<any>(
        `/vital-parameter?take=50`
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @description get observation data for a certain vital parameter
   * @param patientId
   * @param vitalItem
   * @param addedData
   * @param medPlanId
   * @returns
   */
  @action
  public fetchVitalObservations = async (
    patientId: any,
    vitalItem: any,
    addedData: any,
    medPlanId: any
  ) => {
    const { vitalParameterId } = vitalItem;
    if (isEmpty(patientId) || isEmpty(medPlanId) || isEmpty(vitalParameterId))
      return null;

    try {
      let { take, skip, gt } = addedData;
      take = take || 50;
      skip = skip || 0;
      gt = !isEmpty(gt) ? moment(new Date(gt)).format('YYYY-MM-DD') : '';
      let lt: any = moment(new Date()).format('YYYY-MM-DD');
      lt = moment(lt).add(1, 'days');
      lt = moment(lt).format('YYYY-MM-DD');

      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/vital-parameter-observation?patientId=${patientId}&vitalParameterId=${vitalParameterId}&take=${take}&skip=${skip}&ltDate=${lt}&gtDate=${gt}&relations[]=vitalParameter&order[createdAt]=DESC`
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @description post vital param en/disabled status
   * @param medPlanId
   * @param patientId
   * @returns
   */
  @action
  public fetchVitalDataSettings = async (medPlanId: any, patientId: any) => {
    // if (isEmpty(medPlanId) || isEmpty(patientId)) return null;
    try {
      this.setVitalDataSettingsLoaded(false);
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/vital-parameter-setting?patientId=${patientId}`
      );
      this.setVitalDataSettings(res.data);
      this.setVitalDataSettingsLoaded(true);
      return res.data;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @description post vital param en/disabled status
   * @param medPlanId
   * @param patientId
   * @param submitValues
   * @returns
   */
  @action
  public postVitalStatus = async (
    medPlanId: any,
    patientId: any,
    submitValues: any
  ) => {
    if (isEmpty(medPlanId) || isEmpty(submitValues)) return null;
    let { vitalParameterId, enabled } = submitValues;
    enabled = enabled === 'OFF' ? false : enabled;
    try {
      const res: any = await medicationTherapyClient.post<any>(
        `/medication-plan/${medPlanId}/vital-parameter-setting`,
        {
          medicationPlanId: medPlanId,
          vitalParameterId,
          enabled,
          patientId
        }
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   *
   * @description post vital parameter threshold data
   * @param medPlanId
   * @param patientId
   * @param submitValues
   * @returns
   */
  @action
  public postVitalThreshold = async (
    medPlanId: any,
    patientId: any,
    submitValues: any
  ) => {
    if (isEmpty(medPlanId) || isEmpty(submitValues)) return null;
    let everyGood = true;
    await Promise.all(
      submitValues.alarmThreshold.map(async (item: any) => {
        const { parameterId, min, max } = item;

        try {
          const res: any = await medicationTherapyClient.post<any>(
            `/medication-plan/${medPlanId}/vital-parameter-setting/set-alarm-threshold`,
            {
              medicationPlanId: medPlanId,
              parameterId,
              patientId,
              min,
              max,
              color: 'RED'
            }
          );

          const { data } = res;
          if (data === undefined) everyGood = false;
        } catch (e) {
          everyGood = false;
        }
      })
    );
    return everyGood;
  };

  /**
   *
   * @description get info of adherence (medication-not-taken)
   * @param patientId
   * @param take
   * @param skip
   * @returns
   */
  @action.bound async fetchMedicationInTake(
    patientId: string,
    take: number = 10,
    skip: number = 0
  ) {
    if (isEmpty(patientId)) return null;
    try {
      skip = 0; // (skip && this.currentSkip(skip, take)) || 0;
      // possible ?exactDate=${'2020-09-01'}
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-administration?patientId=${patientId}&take=${take}&skip=${skip}&order[exactDateTimeAt]=DESC&noErroneous=true`
      );
      return res;

      //return !isEmpty(data) ? data[0] : undefined;
    } catch (e) {
      return e;
    }
  }

  /**
   *
   * @description get all none taken medication
   * @param patientId
   * @param take
   * @param skip
   * @returns
   */
  @action.bound async fetchNotTakenMedication(
    patientId: string,
    take: number = 30,
    skip: number = 0
  ) {
    if (isEmpty(patientId)) return null;
    try {
      this.notTakenMedLoaded = false;
      //skip = (skip && this.currentSkip(skip, take)) || 0;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/not-taken?patientId=${patientId}&take=${take}&skip=${skip}&order[exactDateTimeAt]=DESC` // &exactDate=2022-09-22
      );
      console.log(res);
      this.notTakenMedLoaded = true;

      this.setNoneTakenMeds(res.data);
      const { data } = res;
      return !isEmpty(data) ? data : undefined;
    } catch (e) {
      return false;
    }
  }

  /**
   *
   * @param warningId
   * @returns
   */
  @action.bound async ackHcpWarning(warningId: string) {
    try {
      const res: any = await medicationTherapyClient.patch<any>(
        `/hcp-warning/${warningId}`
      );
      return true;
    } catch (e) {
      return false;
    }
  }
}
