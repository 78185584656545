import React, { FC } from 'react';
import { Button, Input, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ICasesHeader } from '../../models/components/ICasesHeader';
import getTreatmenStates from '../../config/treatmentState';
import { observer } from 'mobx-react';

const { Option } = Select;

const CasesHeader: FC<ICasesHeader> = ({
  search,
  onchangeFilter,
  searchFilter,
  userAttributes,
  orgConfig,
  invitePatient,
  isArchiv = false,
  chosenFilterOption = '',
  changeFilterTreatmentState = () => {},
  casesLoaded = false
}): JSX.Element => {
  const { t } = useTranslation();
  const treatmentStates = getTreatmenStates(t);
  return (
    <>
      <div
        className="dtb-cases-header stylee-header"
        style={{
          marginBottom: '10px',
          boxSizing: 'border-box'
        }}
      >
        <Input.Search
          disabled={!casesLoaded}
          className="dtb-cases-search"
          style={{ maxWidth: 200, background: '#f2f4f8' }}
          //ssize="default"
          placeholder={t('casestable:search')}
          //prefix={<SearchOutlined />}
          onSearch={(e: any) => search(e)}
          onChange={(e: any) => onchangeFilter(e)}
          value={searchFilter}
        />
        {orgConfig !== null && userAttributes.isPlanner && !isArchiv && (
          <Button
            onClick={invitePatient}
            type="primary"
            block
            style={{ float: 'right', width: 'auto' }}
          >
            {t('actions:addPatient')}
          </Button>
        )}

        {isArchiv && (
          <Tooltip
            placement="topLeft"
            title={t('casestable:filterStatusTooltip')}
          >
            <Select
              disabled={!casesLoaded}
              defaultValue={t(
                `statuscodes:${chosenFilterOption.toLowerCase()}`
              )}
              style={{
                width: '200px',
                display: 'inline-block',
                float: 'right'
              }}
              dropdownClassName="select-plan-item"
              onChange={(e) => changeFilterTreatmentState(e)}
              //onSelect={doSelect}
            >
              {treatmentStates.map((item: any) => {
                const { label, value } = item;
                return (
                  <Option
                    //className="select-option"
                    key={value}
                  >
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default observer(CasesHeader);
