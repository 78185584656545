import React from 'react';
import disabledBirthdays from '../../services/disabledBirthdays';
import moment from 'moment';
import { isEmpty } from 'lodash';

const patientTemplate = (
  t: Function,
  selectedCase: any = null,
  viewMode: boolean,
  dateFormat: string
) => {
  let patientObj: any;
  const genderOptions = [
    { label: t('patient:male'), value: 'MALE' },
    { label: t('patient:female'), value: 'FEMALE' },
    { label: t('patient:diverse'), value: 'DIVERSE' },
    {
      label: t('patient:decline_to_specify'),
      value: 'DECLINE_TO_SPECIFY'
    }
  ];

  if (selectedCase !== null && selectedCase?.patient !== undefined) {
    const { patient } = selectedCase;
    let { firstName, lastName, gender, birthdayAt, birthdayString } = patient;

    const defaultGender = !isEmpty(gender) && gender.toLowerCase();
    gender = !isEmpty(gender) && gender.toUpperCase();
    patientObj = {
      firstName: firstName,
      lastName: lastName,
      //viewMode
      //? moment(birthdayString).format('DD.MM.YYYY')
      //: birthdayString === null
      //? ''
      //: undefined, //moment(birthdayString),
      //gender: viewMode ? t(`patient:${defaultGender}`) : gender

      gender: t(`patient:${defaultGender}`)
    };
  }

  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    initialValues: {
      ...patientObj,
      birthdayString:
        selectedCase?.birthdayString === null &&
        selectedCase?.birthdayString?.length > 0
          ? moment(selectedCase?.birthdayString)
          : selectedCase?.birthdayString?.length > 0
          ? moment(selectedCase?.birthdayString)
          : viewMode
          ? undefined
          : undefined,
      gender:
        patientObj?.gender && patientObj.gender.length > 0
          ? viewMode
            ? t(`patient:${patientObj?.gender}`)
            : patientObj.gender.length > 0
            ? t(`patient:${patientObj?.gender}`)
            : undefined
          : undefined
    },
    fields: [
      {
        key: 'label1',
        colSpan: 4,
        render() {
          return (
            <fieldset>
              <legend>{t('patient:patient')}</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'firstName',
        label: t('patient:firstName'),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField')
      },
      {
        key: 'lastName',
        label: t('patient:lastName'),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField')
      },
      {
        key: 'birthdayString',
        label: t('patient:birthday'),
        colSpan: 2,
        widget: 'date-picker',
        widgetProps: {
          format: dateFormat,
          placeholder: t('patient:selectDate'),
          style: { width: '100%' },
          disabledDate: (current: any) => disabledBirthdays(current),
          allowClear: true
        },
        viewWidget: ({ value }: any) =>
          value !== undefined && value.format(dateFormat),
        required: true,
        message: t('patient:requiredField')
      }
      /*
      {
        key: 'gender',
        label: t('patient:gender'),
        colSpan: 2,
        widget: 'select',
        options: genderOptions,
        //viewWidget: ({ value }: any) =>
        //genderOptions.find((option) => {if(option.value === value) { return option.label } ) ,
        required: true,
        message: t('patient:requiredField')
      }
      */
    ]
  };

  return meta;
};

export default patientTemplate;
