import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

import { patientManagementClient } from '../../axios';

export class OrganisationManagement {
  @observable
  private orgConfig = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get getOrgConfig() {
    return this.orgConfig;
  }

  @action.bound async getConfigToOrganization(organizationId: string) {
    try {
      const res: any = await patientManagementClient.get<any>(
        `/organization/config/${organizationId}`
      );

      this.orgConfig = res.data[0];
      return res;
    } catch (e) {
      return e;
    }
  }
}
