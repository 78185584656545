import React from 'react';
import { isEmpty } from 'lodash';
import findPatientItem from '../../services/findpatientItem';

const patientContactTemplate = (
  t: Function,
  patient: any,
  selectedCase: any,
  viewMode: boolean
) => {
  let patientObj;

  let phone: any = {};
  if (!isEmpty(patient) && patient !== null) {
    phone =
      patient !== null && patient?.length > 0
        ? findPatientItem(patient, 'PatientPhone')
        : patient;
  }

  const { phones } =
    !isEmpty(phone) && !isEmpty(phone.content) && phone.content;

  const phoneItem =
    !isEmpty(phones) && phones.find((item: any) => item.name === 'phone');
  const { number: phoneNumber } = !!phoneItem && phoneItem;
  const mobileItem =
    !isEmpty(phones) && phones.find((item: any) => item.name === 'mobile');
  const { number: mobileNumber } = !!mobileItem && mobileItem;

  patientObj = {
    email: selectedCase?.patient?.eMail,
    phone: viewMode && isEmpty(phoneNumber) ? '-' : phoneNumber,
    mobilephone:
      (viewMode && isEmpty(mobileNumber)) || (viewMode && mobileNumber !== null)
        ? '-'
        : mobileNumber
  };

  const meta = {
    columns: 24,
    formItemLayout: null,
    colon: true,
    initialValues: patientObj,
    fields: [
      {
        key: 'label2',
        colSpan: 24,
        render() {
          return (
            <fieldset>
              <legend>{t('patient:contact')}</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'phone',
        label: t('patient:phone'),
        colSpan: 12,
        required: false,
        message: t('patient:requiredField')
      },
      {
        key: 'mobilephone',
        label: t('patient:mobilePhone'),
        colSpan: 12,
        required: false,
        message: t('patient:requiredField')
      },
      {
        key: 'email',
        label: t('patient:email'),
        colSpan: 12,
        required: false,
        disabled: true,
        message: t('patient:requiredField'),
        rules: [
          {
            required: false,
            type: 'email',
            message: t('patient:validateEmail')
          }
        ]
      }
    ]
  };

  return meta;
};

export default patientContactTemplate;
