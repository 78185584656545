import React, { FC } from 'react';
import { Table } from 'antd';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import casesTableTemplate from '../../../templates/tables/casesTableTemplate';
import casesSkeletonTableTemplate from '../../../templates/tables/casesSkeletonTableTemplate';
import { ICasesTable } from '../../../models/components/ICasesTable';
import { useTranslation } from 'react-i18next';
import settings from '../../../config/settings';
import {
  expandedRowKeys,
  mapDescriptionRow
} from '../../OnkoTableExpandesRows/OnkoTableExpandesRows';
import { isEmpty } from 'lodash';
import CasesWarningBanners from '../../CasesWarningBanners/CasesWarningBanners';

var lastScrollTop = 0;
const CasesTable: FC<ICasesTable> = ({
  patientCareStore,
  medicationTherapyStore
}): JSX.Element => {
  const heightDistance = settings.casesTableHeight;
  const [height, setHeight] = useState(window.innerHeight - heightDistance);
  const [minAnimation, setMinAnimation] = React.useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const casesLoaded: boolean = patientCareStore.getCasesLoaded;
  // const [casesList, setCasesList] = useState([]);

  useEffect(() => {
    handleScreenResize();

    setTimeout(() => {
      setMinAnimation(false);
    }, settings.minAnimation);

    /**
     * @description call resize scroll evvents
     */
    window.addEventListener('resize', handleScreenResize);

    /**
     * @descrription get dom entry selector
     */
    const caseTable = document.querySelector('.ant-table-body');

    /**
     * @description add eventlistener scroll event
     */
    let oldValue = 0;
    let newValue = 0;

    caseTable?.addEventListener('scroll', async () => {
      if (patientCareStore.casesLoadedMore) {
        return;
      }
      console.log('scrooll');
      if (patientCareStore.casesLoadedMore) {
        console.log(patientCareStore.casesLoadedMore);
        // setCasesLoadedMore(true);
      } else {
        const { allCasesLoaded } = patientCareStore;

        newValue = caseTable.scrollTop;

        if (!allCasesLoaded) {
          const perc =
            (caseTable.scrollTop /
              (caseTable.scrollHeight - caseTable.clientHeight)) *
            100;
          if (perc >= 85 && oldValue < newValue) {
            console.log(caseTable.scrollTop);
            console.log('todo fixme load more');
            // await this.loadMoreContent();
            // await patientCareStore.getMoreCases();
            //  setCasesLoadedMore(true);
          }
        }
      }

      /*
      
      */
      oldValue = newValue;
    });
  });

  /**
   * @description handleScreenResize
   */
  const handleScreenResize = (): void => {
    const contentHeight: any = document.querySelector('.ant-layout-content');
    setHeight(contentHeight?.clientHeight - heightDistance);
  };

  /**
   *
   * @description navigate to link
   * @param param0
   */
  const navigateToLink = ({
    caseId,
    patientId
  }: {
    caseId: string;
    patientId: string;
  }) => {
    navigate(`/patient/${patientId}/case/${caseId}/monitoring`);
  };

  /**
   *
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  function onChange(
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ): void {
    console.log('params', pagination, filters, sorter, extra);
  }

  /**
   * @description get template
   */
  const columns = !casesLoaded
    ? casesSkeletonTableTemplate(t)
    : casesTableTemplate(t);

  /**
   * @decription getter
   */
  const casesList: any = patientCareStore.getCases;

  /**
   * @descriiption open all warningbanners filter open list
   */
  const warnRowIds = expandedRowKeys(
    casesList.filter((i: any) => {
      const warnings = i.currentTherapyPlan && i.currentTherapyPlan.warnings;
      const filteredWarnings =
        !isEmpty(warnings) &&
        warnings.length > 0 &&
        warnings.filter(
          (warning: any) =>
            !!warning.reason ||
            (!isEmpty(warning.reason) &&
              warning.reason.reasonState &&
              warning.reason.reasonState !== 'ENTERED-IN-ERROR')
        );
      return filteredWarnings && filteredWarnings.length > 0;
    })
  );

  return (
    <Table
      onRow={(r: any) => ({
        onClick: () =>
          navigateToLink({ caseId: r?.id, patientId: r?.patientId })
      })}
      rowKey={(i) => i.id}
      scroll={{ y: height, x: 500 }}
      pagination={false}
      columns={columns}
      dataSource={
        casesLoaded
          ? casesList
          : [...Array(settings.casesAnimation.counterSkeleton)].map(
              (_, i) => i + 1
            )
      }
      onChange={onChange}
      loading={settings.casesAnimation.spinner && !casesLoaded}
      key="loading-done"
      defaultExpandAllRows={true}
      expandable={{
        defaultExpandedRowKeys: warnRowIds,
        expandedRowKeys: minAnimation ? false : warnRowIds || '',
        defaultExpandAllRows: true,
        expandIcon: () => null, // icon
        expandRowByClick: false,
        showExpandColumn: false,
        // rowExpandable: true,
        expandedRowRender: (entry) => {
          return (
            <CasesWarningBanners
              entry={entry}
              casesLoaded={casesLoaded}
              patientCareStore={patientCareStore}
              medicationTherapyStore={medicationTherapyStore}
            />
          );
        }
      }}
    />
  );
};

export default inject(
  'patientCareStore',
  'medicationTherapyStore'
)(observer(CasesTable));
