import React, { FC } from 'react';
import { isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IPatientBodyDetails } from '../../models/components/IPatientBodyDetails';
import {
  IMedplan,
  IUnderlyingPatientInformation
} from '../../models/data/IMedplan';

const PatientBodyDetails: FC<IPatientBodyDetails> = ({
  medplan
}): JSX.Element => {
  const { t } = useTranslation();
  const { underlyingPatientInformation }: any = !isEmpty(medplan) && medplan;
  const { height, weight, bodySurface }: IUnderlyingPatientInformation =
    !isEmpty(underlyingPatientInformation) && underlyingPatientInformation;

  return (
    <>
      {t('patient:bodyHeight')}
      {': '}
      {(!isNil(height) && height + ' cm') || '-'}
      <br />
      {t('patient:bodyWeight')}
      {': '}
      {(!isNil(weight) && weight + ' kg') || '-'}
      <br />
      {t('patient:bodySurface')}
      {': '}
      {(!isNil(bodySurface) && bodySurface + ' qm') || '-'}
    </>
  );
};

export default PatientBodyDetails;
