import moment from 'moment';
import { isEmpty } from 'lodash';
import { splitDataToCharts } from './global';

const dateFormat = 'DD.MM.YYYY HH:mm';

/**
 * @description transform oberservationData to chart / list structure
 * @param {*} observationData
 */
const transformToVitalChartStructure = async (
  observationData: any,
  settingsItem: any
) => {
  if (isEmpty(observationData)) return null;
  const viewData = observationData.map((item: any) => {
    const { createdAt, values, validOnDateAt } = item;
    const parsVal = (value: any) =>
      parseFloat(value.toString().replace(/,/g, '.'));
    const val1 = values[0] && parsVal(values[0].value);
    const val2 = values[1] && parsVal(values[1].value);
    let value = !isNaN(val1) ? val1 : '-';
    const { parameterDefinitions } =
      !isEmpty(item.vitalParameter) && item.vitalParameter;
    const parmDef = parameterDefinitions && parameterDefinitions[0];

    if (values.length > 1 && !isEmpty(values[0]) && !isEmpty(values[1])) {
      const parm1 = values[0].parameterId;
      const val1Id = parm1.charAt(parm1.length - 1);
      // display values according their parm id 1/2
      value = val1Id === 1 ? value + ' / ' + val2 : value + ' / ' + val2;
    }
    const unit = parmDef && ((parmDef.unit !== 'Smiley' && parmDef.unit) || '');

    return {
      x: moment(new Date(validOnDateAt)).toISOString(), //.format(dateFormat), /
      y: val1,
      y2: val2,
      yUnit: unit,
      status: vitalDataStatus(item, settingsItem),
      value, // if item has 2 values then both combined displayment in list/table view
      validOnDateAt,
      createdAt
    };
  });

  return viewData;
};

const generateVitalSplitCharts = (vitalParameter: any, viewData: any) => {
  // disable if (isEmpty(observationsData) || isEmpty(viewData)) return null;
  // disable const { vitalParameter } = observationsData[0];
  const { threshold: parmDef } = !isEmpty(vitalParameter) && vitalParameter;
  // possible value for lineSerie and markSeries one
  const paramDef1 = !isEmpty(parmDef) && parmDef[0];
  // possible value for lineSerie and markSeries two
  const paramDef2 = !isEmpty(parmDef) && parmDef[1];
  const { lineSeries, markSeries }: any = splitDataToCharts(viewData);
  const hasSecondChartData = viewData.some((item: any) => item.y2);
  const { lineSeries2, markSeries2 } =
    hasSecondChartData && splitDataToCharts(viewData, true);

  const chartData = {
    lineSeries,
    markSeries,
    lineSeries2,
    markSeries2,
    paramDef1,
    paramDef2,
    step: 1,
    min: !isEmpty(paramDef1) && parseInt(paramDef1.min),
    max: !isEmpty(paramDef1) && parseInt(paramDef1.max),
    min2: !!paramDef2 && parseInt(paramDef2.min),
    max2: !!paramDef2 && parseInt(paramDef2.max),
    isSideEffect: false
  };
  return chartData;
};

const vitalDataStatus = (observationItem: any, settingsItem: any) => {
  if (isEmpty(observationItem)) return null;
  let data = observationItem;
  data =
    data.length > 1
      ? data.reduce((a: any, b: any) =>
          a.values[0].value > b.values[0].value ? a : b
        )
      : data; // if data is an array find the item with the highest value

  /**
   * @description check all values
   */
  const { values } = data;
  const { alarmThreshold } = !isEmpty(settingsItem) && settingsItem;
  let defaultStatus = undefined;

  // if current value is in range, the item is green else is red
  values.map(
    (item: any) => (defaultStatus = colorStatus(item, alarmThreshold))
  );
  return defaultStatus;
};

/*
 * check if a certain observation value is in range, if so, result is 0 (green), not red
 * and if no alarmSettings exists, then undefined (for not being checkable)
 */
const colorStatus = (valueItem: any, alarmThreshold: any) => {
  if (isEmpty(valueItem) || valueItem === undefined || isEmpty(alarmThreshold))
    return undefined;
  let { parameterId: parmId, value: val } = valueItem;
  val = parseFloat(val);

  const alarmSetting = alarmThreshold.find(
    (item: any) => item.parameterId === parmId
  );
  if (alarmSetting === undefined) return undefined;
  let { min, max } = !isEmpty(alarmSetting) && alarmSetting;
  min = min !== undefined ? parseFloat(min) : min;
  max = max !== undefined ? parseFloat(max) : max;
  let isInRange;

  // if only max data exsits
  if (max !== undefined) {
    isInRange = val < max;
  }
  // if only min data exsits
  if (min !== undefined) {
    isInRange = val > min;
  }
  // if min & max data exsits
  if (min !== undefined && max !== undefined) {
    isInRange = val > min && val < max;
  }

  return isInRange ? 0 : 'RED'; // 0 === green everything is good, red === is alarm
};

export {
  generateVitalSplitCharts,
  vitalDataStatus,
  transformToVitalChartStructure
};
