const settings = {
  minAnimation: 800,
  casesTableHeight: 168,
  casesAnimation: {
    counterSkeleton: 1,
    spinner: false
  }
};

export default settings;
