import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const supportedLanguages = ['de', 'en'];

i18next
  // lazy loading load translation
  .use(Backend)
  // .use(XHR)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: 'de',
    debug: false, // debug testing
    backend: [
      LocalStorageBackend, // primary
      XHR // fallback
    ],

    interpolation: {
      /*
      format: function(value, format, lng) {
        if (format === 'bold') return value.toUpperCase();
        //if(value instanceof Date) return moment(value).format(format);
        return '<i>'+ value+ '</i>';
      },
      */
      useRawValueToEscape: false,
      escapeValue: false // react already safes from xss
    },
    /*
    detection: {
      order: ["path", "navigator"]
    },
    */
    react: {
      bindI18n: 'languageChanged'
    }
  });

export default i18next;

//import de from './de';
//import en from './en';

/*
i18next.use(initReactI18next).init({
  interpolation: {
    // react does escaping by default
    escapeValue: false,
    // example for formatting. see https://www.i18next.com/translation-function/formatting
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    }
  },
  lng: 'de', //`${window.navigator.language.slice(0, 2)}`,
  fallbackLng: 'de',
  resources: {
    en: {
      translation: {
        keyss: 'hello world'
      }
    },
    de: {
      test: 'hmms',
      translation: {
        keyss: 'hallo welt'
      }
    }
    // de,
    // en
  }
});

export default i18next;
*/
