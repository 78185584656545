import React, { FC } from 'react';
import { Badge, Tooltip, Typography } from 'antd';
import CasesTooltipSideffectsListThresholdLamp from '../CasesTooltipSideffectsListThresholdLamp/CasesTooltipSideffectsListThresholdLamp';
import trafficLightThresholdColors from '../../config/trafficLightThresholdColors';
import { isEmpty } from 'lodash';
import { ICasesSideEffectsThresholdTooltip } from '../../models/components/ICasesSideEffectsThresholdTooltip';

const { Text } = Typography;

const CasesSideEffectsThresholdTooltip: FC<ICasesSideEffectsThresholdTooltip> = ({
  alarmThresholdReview,
  len
}): JSX.Element => {
  /**
   * @description get traffic light config
   */
  const teestt = alarmThresholdReview?.map((item: any) => {
    return item?.values?.[0];
  });

  const highestValue =
    teestt !== undefined
      ? Math.max(...teestt.filter((item: any) => item !== '-1'))
      : null;
  const fu =
    highestValue === -Infinity || highestValue === NaN || highestValue === null
      ? '-1'
      : highestValue;

  const bg = trafficLightThresholdColors(fu);
  /**
   * @description change color in different alaram threshold
   */
  const colorText =
    alarmThresholdReview?.[0]?.values?.[0] < 1 ||
    alarmThresholdReview?.[0]?.values?.[0] > 2
      ? '#333'
      : '#333';

  const toolTipCase = CasesTooltipSideffectsListThresholdLamp(
    alarmThresholdReview
  );

  return (
    <Tooltip
      overlayClassName="tool-tip-item side-effect"
      placement="bottomLeft"
      title={toolTipCase}
    >
      <div
        style={{
          width: 40,
          height: 40,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Badge
          count={alarmThresholdReview?.length}
          //className={highestValue === undefined ? 'badge none' : ''}
          style={{
            border: 'solid 1px rgba(49,50,55,.5)',
            cursor: 'pointer',
            color: colorText, //amountFontColor
            backgroundColor: bg || 'transparent',
            lineHeight: '1.5'
          }}
          title=" " // this is needed to avoid double tooltip
        />
      </div>
    </Tooltip>
  );
};

export default CasesSideEffectsThresholdTooltip;
