import React, { FC } from 'react';
import { Avatar, Typography } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import { ICasesAvatar } from '../../models/components/ICasesAvatar';

const { Text } = Typography;

const CasesAvatar: FC<ICasesAvatar> = ({ entry }): JSX.Element => {
  const dateFormat: string =
    i18next.language === 'de' ? 'DD.MM.YYYY' : 'YYYY-MM-DD';
  return (
    <div
      style={{
        width: 100,
        minWidth: '100%',
        overflow: 'hidden'
        //background: 'blue'
      }}
    >
      <Avatar
        size={40}
        style={{
          display: 'flex',
          alignItems: 'center',
          // display: 'inline-block',
          textAlign: 'left',
          float: 'left'
        }}
      >
        {entry?.patientFirstName?.charAt(0) + entry?.patientLastName?.charAt(0)}
      </Avatar>
      <p
        className="dtb-username"
        style={{
          marginLeft: 10,
          height: 40,
          marginBottom: 0,
          // minWidth: 170,
          display: 'inline-block',
          textAlign: 'left',
          float: 'left',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 'calc(100% - 60px)'
        }}
      >
        <span
          style={{
            width: '100%',
            // minWidth: 170,
            display: 'inline-block',
            textAlign: 'left',
            float: 'left',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          {entry.patientLastName}, {entry.patientFirstName}
        </span>
        <br /> {moment(entry.patientBirthdayString).format(dateFormat)}
        {/*JSON.stringify(entry)*/}
      </p>{' '}
    </div>
  );
};

export default CasesAvatar;
