import { action, computed, makeObservable, observable } from 'mobx';
import { patientManagementClient } from '../../axios';

export class HcpStore {
  @observable
  private Hcps = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  public getHcp = async (id: string) => {
    if (this.Hcps.has(id)) {
      return this.Hcps.get(id);
    } else {
      try {
        const res: any = await patientManagementClient.get<any>(`/hcps/${id}`);
        this.Hcps.set(res.data.id, res.data);
        return res.data;
      } catch (e) {
        return e;
      }
    }
  };
}
