import moment from 'moment';
import { isEmpty } from 'lodash';
import { splitDataToCharts } from './global';

const dateFormat = 'DD.MM.YYYY HH:mm';

/**
 * @description transform oberservationData to chart / list structure
 * @param {*} observationData
 */
const transformToSideChartStructure = async (
  observationData: any,
  settingsItem: any
) => {
  if (isEmpty(observationData)) return null;
  const viewData = observationData.map((item: any) => {
    const { createdAt, value, validOnDateAt } = item;

    return {
      x: moment(new Date(validOnDateAt)).toISOString(), //.format(dateFormat),
      y: value,
      status: colorStatus({ value, settingsItem }),
      value, // for list view (needed for vital data which can have two values)
      validOnDateAt,
      createdAt
    };
  });

  return viewData;
};

/*
 * check if a certain observation value is in range, if so, result is 0 (green), not red
 * and if no alarmSettings exists, then undefined (for not being checkable)
 */
const colorStatus = ({ value, settingsItem: { alarmThreshold } }: any) => {
  if (value === undefined) return undefined;
  if (isEmpty(alarmThreshold) || alarmThreshold[0] === undefined) return value;
  let max = alarmThreshold[0].max;
  max = max && max !== undefined ? parseFloat(max) : max;

  // check is value is smaller them then max everything is good, else return RED as warning
  const isInRange = parseFloat(value) < max;
  return isInRange ? value : 'RED';
};

const generateSideEffectsSplitCharts = (
  observationData: any,
  viewData: any,
  sideEffect: any
) => {
  if (isEmpty(observationData) || isEmpty(sideEffect)) return null;
  const { possibleValues } = sideEffect;
  const { lineSeries, markSeries }: any =
    !isEmpty(viewData) && splitDataToCharts(viewData);
  const chartData = {
    lineSeries,
    markSeries,
    possibleValues,
    step: 1,
    min: Math.min(...possibleValues.map((item: any) => item.value)),
    max: Math.max(...possibleValues.map((item: any) => item.value)),
    isSideEffect: true
  };
  return chartData;
};

export { generateSideEffectsSplitCharts, transformToSideChartStructure };
