import React, { useState } from 'react';
import { Layout, Drawer, ConfigProvider } from 'antd';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { useStores } from './use-stores';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from './i18n';
import deDe from 'antd/lib/locale/de_DE';
import enUs from 'antd/lib/locale/en_US';
import jwt_decode from 'jwt-decode';
import keycloak from './keycloak';
import NavBar from './core/components/Header/Header';
import About from './core/pages/About/About';
import PatientManagement from './patientmanagement/PatientManagement';
import './App.scss';
import './App-mobil.scss';
import Menuw from './core/components/Menu/Menu';
import coreDE from './core/locale/de/core';
import coreEN from './core/locale/en/core';
import coreNavDE from './core/locale/de/nav';
import coreNavEN from './core/locale/en/nav';
import logoSrc from './assets/logo.svg';
import Imprint from './core/pages/Imprint/Imprint';
import NotificationDrawer from './core/components/NotificationSidebar/NotificationSidebar';

const { Header } = Layout;

i18next.addResourceBundle('de', 'core', coreDE);
i18next.addResourceBundle('en', 'core', coreEN);
i18next.addResourceBundle('de', 'coreNav', coreNavDE);
i18next.addResourceBundle('en', 'coreNav', coreNavEN);

const App = () => {
  const [minAnimation, setMinAnimation] = React.useState(true);
  const [navVisible, setNavVisible] = React.useState(false);
  const [detailInfoVisible, setDetailInfoVisible] = useState(false);
  const { t, i18n } = useTranslation();


  const showNav = () => {
    setNavVisible(true);
  };

  const onCloseNav = () => {
    setNavVisible(false);
  };

  const showDetail = () => {
    setDetailInfoVisible(true);
  };

  const onCloseDetail = () => {
    setDetailInfoVisible(false);
  };

  const toogleCanvasMenu = () => {
    const canvasElement: HTMLElement | null = document.getElementById(
      'offcanvas'
    );
    const bgcanvas: HTMLElement | null = document.getElementById('bgcanvas');
    bgcanvas?.classList.add('bgcanvasshow');
    if (
      canvasElement !== null &&
      !canvasElement.classList.contains('open-canvas')
    ) {
      canvasElement.classList.add('open-canvas');
    } else {
      canvasElement?.classList.remove('open-canvas');
      bgcanvas?.classList.remove('bgcanvasshow');
    }
  };

  const stores = useStores();
  const {
    authStore,
    organisationManagement,
    uiStore,
    patientCareStore,
    messagingStore,
    therapyStore,
    medicationTherapyStore,
    hcpStore
  } = stores;
  const { getAuthenticated, getUserAttributes } = authStore;
  const authenticated = getAuthenticated;
  const userAttributes = getUserAttributes;
  const isMobileIconVisible: boolean = uiStore.getCaseViewMobil
   
  setTimeout(() => {
    setMinAnimation(false);
  }, 800);

  const getConfig = async () => {
    if (keycloak.token !== undefined) {
      const decodeToken: any = jwt_decode(keycloak.token);
      await organisationManagement.getConfigToOrganization(
        decodeToken.organizationIds[0]
      );
    }
  };

  if (minAnimation || userAttributes.isDoctor === null) {
    return (
      <Layout className="dtb-init-loader">
        <img
          style={{ marginLeft: '20px' }}
          src={logoSrc}
          alt="TinoDTB"
          height="80"
        />
        <h1
          data-text={
            !authenticated && userAttributes.isDoctor !== null
              ? t('core:checkingPermissionStatus')
              : t('core:forMedicalInstitutions')
          }
        >
          {!authenticated && userAttributes.isDoctor !== null
            ? t('core:checkingPermissionStatus')
            : t('core:forMedicalInstitutions')}
        </h1>
      </Layout>
    );
  } else {
    // load org details
    getConfig();
   

    return (
      <ConfigProvider locale={i18n.language === 'de' ? deDe : enUs}>
        <Provider
          stores={stores}
          uiStore={uiStore}
          authStore={authStore}
          organisationManagement={organisationManagement}
          patientCareStore={patientCareStore}
          messagingStore={messagingStore}
          therapyStore={therapyStore}
          medicationTherapyStore={medicationTherapyStore}
          hcpStore={hcpStore}
          theme={'light'}
        >
          <div className="dtb-layout">
            <BrowserRouter>
              <Header className="dtb-header">
                <NavBar
                  toogleCanvasMenu={toogleCanvasMenu}
                  showNav={showNav}
                  showDetail={showDetail}
                  organisationManagement={organisationManagement}
                  isMobileIconVisible={isMobileIconVisible}
                />
              </Header>
              <div className="dtb-main-content">
                <Routes>
                  {/**
                   * @description route config: aceppted all user if auth
                   */}
                  <Route key="1" path="/about" element={<About />} />
                  <Route key="2" path="/imprint" element={<Imprint />} />
                  {/**
                   * @description route config: only auth true and attribute isDosctor true
                   */}
                  {authenticated && userAttributes.isDoctor ? (
                    <Route
                      key="2"
                      path="/*"
                      element={
                        <PatientManagement
                          detailInfoVisible={detailInfoVisible}
                          onCloseDetail={onCloseDetail}
                        />
                      }
                    />
                  ) : (
                    <Route
                      key="3"
                      path="/*"
                      element={
                        <Layout className="fanta">
                          <h3>
                           {t('core:noPermissionWarning')}
                          </h3>
                        </Layout>
                      }
                    />
                  )}
                  <Route key="4" path="*" element={<>error</>} />
                </Routes>
                
                {/*<Button type="primary" onClick={yxcshowDrawer}>
              Open drawer
              </Button>**/}
                <NotificationDrawer
                  t={t}
                  toogleCanvasMenu={toogleCanvasMenu}
                  ref={(notificationDrawer: any) => {
                    notificationDrawer = notificationDrawer;
                  }}
                />

                {/** left */}
                <Drawer
                  title="Patientenbetreuung"
                  width={200}
                  closable={false}
                  onClose={onCloseNav}
                  visible={navVisible}
                  placement={'left'}
                >
                  <div>
                    <Menuw />
                  </div>
                </Drawer>
              </div>
            </BrowserRouter>
          </div>
        </Provider>
      </ConfigProvider>
    );
  }
};

export default withTranslation('core')(observer(App));
