import React, { useState, createElement } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Avatar, Menu, Typography } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import { IHeader } from '../../models/components/IHeader';
import logoSrc from '../../../assets/logo.svg';
import { ReactComponent as Info } from '../../../assets/info.svg';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import './../../scss/components/Header.scss';

const { Text } = Typography;

const Header = ({
  authStore,
  toogleCanvasMenu,
  theme,
  showNav,
  showDetail,
  organisationManagement,
  isMobileIconVisible
}: IHeader) => {
  const [collapsed, toggleMenu] = useState(false);
  const { SubMenu, Item, ItemGroup } = Menu;
  const { t } = useTranslation();
  const { user, userAttributes } = authStore;
  const { goToProfil, logout } = authStore;
  const config = organisationManagement.getOrgConfig;
  const menuItems: any = [
    {
      label: (
        <Avatar size={40} style={{ marginTop: '-5px' }}>
          {user?.firstName?.charAt(0) + user?.lastName?.charAt(0)}
        </Avatar>
      ),
      key: 'submenu1',
      className: 'right navbar-item',
      children: [
        {
          label: 'Profil',
          icon: <UserOutlined />,
          key: 'submenu-item-1',
          onClick: () => goToProfil()
        },
        {
          label: 'Abmelden',
          icon: <LogoutOutlined />,
          key: 'submenu-item-2',
          onClick: () => logout()
        }
      ]
    },
    {
      label: '',
      icon: <BellOutlined />,
      key: 'item-1',
      onClick: toogleCanvasMenu
    },
    {
      label: '',
      key: 'submenu2',
      icon: <QuestionCircleOutlined />,
      children: [
        {
          label: <NavLink to="/about">Über Uns</NavLink>,
          icon: <Info />,
          key: 'submenu2-item-1'
        },
        {
          label: <NavLink to="/imprint">Impressum</NavLink>,
          icon: <Info />,
          key: 'submenu2-item-2'
        }
      ]
    }
  ];

  const getGreetingBasedOnTime = () => {
    const d = new Date();
    const time = d.getHours();

    if (time >= 0 && time < 9) {
      return t('core:goodMorning');
    }

    if (time >= 9 && time < 18) {
      return t('core:hello');
    }

    if (time >= 18 && time < 24) {
      return t('core:goodEvening');
    }

    return t('core:hello');
  };

  return (
    <div className="head-wrapper dtb-head-wrapper">
      <div>
        {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: () => showNav()
        })}

        <Link className="none-mobil dtb-logo-link" to="/">
          <img className="dtb-logo" src={logoSrc} alt="TinoDTB" height="23" />
          <span className="dtb-header-subtitle">
            {t('core:header.subtitle')}
          </span>
        </Link>

        {config !== null && userAttributes.isPlanner && (
          <a className="dtb-planner-link" href={`${config.planerHome}`}>
            {t('coreNav:therapyManager')}
          </a>
        )}
      </div>

      <div className="dtb-language-switch">
        {isMobileIconVisible && React.createElement(
          collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
          {
            className: 'trigger-two',
            style: { float: 'right' },
            onClick: () => showDetail()
          }
        )}
        <LanguageSwitch />
        <Text className="dtb-grettings">
          {getGreetingBasedOnTime() +
            ', ' +
            authStore.user.firstName +
            ' ' +
            authStore.user.lastName}
        </Text>
        <Menu
          className="dtb-navbar-menu-container"
          mode="horizontal"
          selectable={false}
          theme="light"
          style={{ float: 'right' }}
          items={menuItems}
        />
      </div>
    </div>
  );
};

export default withTranslation('core')(
  inject('authStore', 'organisationManagement')(observer(Header))
);
