import { action, makeObservable, observable } from 'mobx';
import { therapyClient } from '../../axios';

export class TherapyStore {
  @observable
  public diagnoses: any = null;

  constructor() {
    makeObservable(this);
  }

  /**
   * @description get diagnoses
   * @param patientId
   * @returns
   */
  @action
  public getMedplaAdministration = async (patientId: string) => {
    try {
      const res: any = await therapyClient.get<any>(
        `/condition?patientId=${patientId}`
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @description get diagnoses
   * @param patientId
   * @returns
   */
  @action
  public getDiagnoses = async (patientId: string) => {
    try {
      const res: any = await therapyClient.get<any>(
        `/condition?patientId=${patientId}`
      );
      this.diagnoses = res.data;
      return res.data[0];
    } catch (e) {
      return e;
    }
  };
}
