import React, { FC } from 'react';
import { Typography, Row, Col } from 'antd';
import { IWarnings } from '../../models/data/IWarnings';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ICasesWarningBanners } from '../../models/components/ICasesWarningBanners';
import i18next from 'i18next';

const { Text } = Typography;

const CasesWarningBanners: FC<ICasesWarningBanners> = ({
  entry,
  casesLoaded,
  medicationTherapyStore,
  patientCareStore
}): JSX.Element => {
  const dateFormat: string =
    i18next.language === 'de' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm';
  return (
    <Row className="dtb-warnings-grid">
      {casesLoaded &&
        entry?.warnings?.map((warning: IWarnings, index: number) => {
          return (
            <Col key={index} span={24} className="dtb-warnings">
              <Text style={{ color: '#fff' }}>
                <WarningOutlined className="dtb-warning-icon" />{' '}
                {moment(entry.createdAt).format(dateFormat)}{' '}
                {warning.reason.reasonObjectName}{' '}
                {warning.reason.affectedValues}
                <CloseOutlined
                  className="right icon-item dtb-warning-baneer-close"
                  onClick={() => {
                    medicationTherapyStore.ackHcpWarning(warning.warningId);
                    patientCareStore.fetchCases();
                  }}
                />
              </Text>
            </Col>
          );
        })}
    </Row>
  );
};

export default CasesWarningBanners;
