const getTreatmenStates = (t: Function): { label: string; value: string }[] => {
  const treatmentState: { label: string; value: string }[] = [
    { label: t(`statuscodes:completed`), value: 'DONE' },
    { label: t(`statuscodes:aborted_done`), value: 'ABORTED_DONE' },
    { label: t(`statuscodes:aborted`), value: 'ABORTED' },
    // { label: t(`statuscodes:not_assigned`), value: 'NOT_ASSIGNED' },
    // { label: t(`statuscodes:started`), value: 'IN_PROGRESS' },
    // { label: t(`statuscodes:on_hold`), value: 'ON_HOLD' },
    // { label: t(`statuscodes:planned`), value: 'PENDING' }
    // { label: t(`statuscodes:result_available`), value: 'RESULT_AVAILABLE' }
  ];
  return treatmentState;
};
export default getTreatmenStates;
