import React, { FC } from 'react';
import { Layout } from 'antd';
import PatientForm from '../../components/PatientForm/PatientForm';
import { IPatient } from '../../models/pages/IPatient';
import './../../scss/Patient.scss';

const Patient: FC<IPatient> = (): JSX.Element => {
  return (
    <Layout className="content-wrapper content-scroll-wrapper">
      <PatientForm />
    </Layout>
  );
};

export default Patient;
