import React from 'react';
import {
  List,
  Collapse,
  Typography,
  Avatar,
  Badge,
  Row,
  Col,
  Tooltip
} from 'antd';
import moment from 'moment';
import { observer } from 'mobx-react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;
const { Title } = Typography;

type Props = {
  unreadMessages: any;
  toogleCanvasMenu: Function;
};

const NewMessageList = ({ unreadMessages, toogleCanvasMenu }: Props) => {
  const { t } = useTranslation();
  const style =
    unreadMessages.length > 0 ? {} : { backgroundColor: '#fff', color: '#999' };
  const num = unreadMessages.length === 0 ? ['1'] : ['1'];
  return (
    <Collapse defaultActiveKey={num} expandIconPosition="right">
      <Panel
        disabled={unreadMessages.length === 0 ? false : false}
        header={
          <div style={{ marginTop: '10px' }}>
            <Title level={3}>
              <Badge
                showZero={true}
                count={unreadMessages.length > 0 ? unreadMessages.length : 0}
                style={style}
              ></Badge>
              <span style={{ marginLeft: '20px', color: '#fff' }}>
                {t('NotificationDrawer.NewMessageList.listItem')}
              </span>
            </Title>
          </div>
        }
        key="1"
      >
        <List
          style={{ background: 'none' }}
          dataSource={unreadMessages}
          renderItem={(entry: any) => (
            <List.Item
              key={entry.id}
              className="notification-msg-wrapper"
              style={{ background: 'none', display: 'block' }}
              onClick={() => {
                toogleCanvasMenu();
              }}
            >
              <Tooltip
                title={'Klicken Sie hier, um direkt in den Raum zu gelangen.'}
              >
                <a href={`#`}>
                  <Row style={{ background: '#333', padding: '6px' }}>
                    <Col span={24}>
                      <div
                        style={{
                          width: '100%',
                          color: '#fff',
                          float: 'left',
                          marginRight: '10px',
                          display: 'block'
                        }}
                      >
                        <div
                          style={{
                            width: '100px',
                            float: 'right',
                            position: 'relative',
                            top: '5px',
                            right: '5px',
                            textAlign: 'right'
                          }}
                        ></div>
                        <Avatar
                          style={{
                            float: 'left',
                            marginRight: '10px',
                            backgroundColor: '#0588E6'
                          }}
                          src={entry?.buddy.avatarUrl}
                          //title={entry?.buddy.displayname}
                          //description={entry.text}
                        >
                          {entry?.buddy?.avatarLetter}
                        </Avatar>
                        <div
                          style={{ float: 'left' }}
                          className="notification-msg"
                        >
                          <span>
                            {entry?.buddy?.displayname} <br />
                          </span>
                          <p
                            style={{
                              wordBreak: 'break-all',
                              color: '#D7D7D7',
                              textOverflow: 'ellipsis',
                              maxWidth: '300px'
                            }}
                          >
                            {entry?.text}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      span={24}
                      style={{ textAlign: 'right', fontSize: '.8em' }}
                    >
                      <span>
                        {moment(entry?.date).format('DD.MM.YYYY HH:mm')}
                      </span>
                    </Col>
                  </Row>
                </a>
              </Tooltip>
            </List.Item>
          )}
        />
      </Panel>
    </Collapse>
  );
};

export default observer(NewMessageList);
