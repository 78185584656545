import React from 'react';
import { MenuProps } from 'antd';
import { Menu } from 'antd';
import { UnorderedListOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Sider = () => {
  const [current, setCurrent] = React.useState('1');

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      style={{
        width: 200,
        height: 'auto',
        display: 'block'
      }}
      defaultOpenKeys={['sub1']}
      selectedKeys={[current]}
      mode="inline"
      items={[
        {
          key: '1',
          label: <Link to="/cases">Behandlungen</Link>,

          icon: <UnorderedListOutlined />
        },
        {
          key: '2',
          label: <Link to="/archiv">Archiv</Link>,
          icon: <DeleteOutlined />
        }
      ]}
    />
  );
};

export default Sider;
