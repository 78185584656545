import React, { FC, Suspense, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Tabs, Layout, Badge, Drawer } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Patient from '../Patient/Patient';
import CaseDetailSidebar from '../../components/CaseDetailSidebar/CaseDetailSidebar';
import CaseDetailHeader from '../../components/CaseDetailHeader/CaseDetailHeader';
import { ICaseDetail } from '../../models/pages/ICaseDetail';
import { useTranslation } from 'react-i18next';
import settings from '../../config/settings';
//import '../../scss/Detail.scss';
//import './Detail.scss';

const Messaging = React.lazy(() => import('../../../messaging/Messaging'));
const Monitoring = React.lazy(() => import('../../../monitoring/Monitoring'));
const Medication = React.lazy(() => import('../../../medication/Medication'));

const { Content } = Layout;
const { TabPane } = Tabs;

const CaseDetail: FC<ICaseDetail> = ({
  uiStore,
  patientCareStore,
  // therapyStore,
  medicationTherapyStore,
  authStore,
  messagingStore,
  detailInfoVisible,
  onCloseDetail
}): JSX.Element => {
  const { tab, caseId, patientId } = useParams();
  const [loader, setLoader] = useState(true);
  const [minAnimation, setMinAnimation] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const { setCaseViewMobil } = uiStore;
    const { fetchCase } = patientCareStore;
    /**
     * @description needed header mobil hamburger menu right
     */
    setCaseViewMobil(true);

    const getCase = async () => {
      setLoader(false);
      setMinAnimation(true);
      /**
       * @description init loading datas apis
       * - load case (all information of patient is inside case item)
       * - load messaging counter (polling 5sec implemenation)
       * - load current medplan (needed in sidebar)
       */
      // case data
      const caseData = await fetchCase(caseId);

      // load messages messaging
      if (
        caseData !== null &&
        caseData !== undefined &&
        caseData.hasOwnProperty('messageProcessId') &&
        caseData.messageProcessId !== null
      ) {
        // messaging counter polling
        setInterval(async () => {
          await messagingStore.fetchUnreadMessageCount(
            caseData?.messageProcessId,
            true
          );
        }, 10000);
      }

      // min animation
      setInterval(async () => {
        setMinAnimation(false);
      }, settings.minAnimation);

      // load current medplan
      const getCurrentMedPlan = await medicationTherapyStore.fetchCurrentMedPlan(
        patientId
      );
      console.log(getCurrentMedPlan);
      const medi = await medicationTherapyStore.getMedication(
        getCurrentMedPlan.id
      );
      console.log(medi);

      if (getCurrentMedPlan.data.length > 0) {
        await medicationTherapyStore.fetchAlarmtTresholdReviewDay(
          getCurrentMedPlan.data[0][0].id,
          patientId
        );
      }
      /**
       * @description temp disabled
       **/
      //const res2 = await therapyStore.getDiagnoses(patientId);

      /*
      // load therapy plans
      await medicationTherapyStore.fetchTherapyPlans(patientId, 0);

      medicationTherapyStore.fetchAlarmtTresholdReviewDay(
        getCurrentMedPlan.data[0][0].id,
        patientId
      );

      if (getCurrentMedPlan.data[1] > 0) {
        // load medication
        const data = await medicationTherapyStore.getMedication(
          getCurrentMedPlan.data[0][0].id
        );

        const adherence = await medicationTherapyStore.fetchMedicationInTake(
          patientCareStore.selectedCase.patient.patientId
        );

        // load side effects
        const sideEffectSettings = await medicationTherapyStore.fetchSideEffectsSettings(
          patientId,
          getCurrentMedPlan.data[0][0].id
        );

        // medicationTherapyStore.fetchSideEffectData()
        medicationTherapyStore.getObservationData(
          patientCareStore.selectedCase,
          sideEffectSettings.data,
          'side'
        );

        // load vitaldata settings
        const vitalDataSettings = await medicationTherapyStore.fetchVitalDataSettings(
          getCurrentMedPlan.data[0][0].id,
          patientId
        );

        medicationTherapyStore.getObservationData(
          patientCareStore.selectedCase,
          vitalDataSettings,
          'vital'
        );
        // load medicaion in take
        await medicationTherapyStore.fetchNotTakenMedication(patientId);
      }
      */
    };

    setTimeout(() => {
      setMinAnimation(false);
    }, settings.minAnimation);
    loader && getCase();

    return () => {
      /**
       * @description clear header mobil hamburger menu right
       */
      uiStore.setCaseViewMobil(false);
      medicationTherapyStore.setCurrentMedplan(null);
      medicationTherapyStore.setTherapyPlansList([]);
    };
  }, [
    caseId,
    loader,
    medicationTherapyStore,
    patientCareStore,
    patientId,
    messagingStore,
    uiStore
  ]);

  const onClose = () => {
    onCloseDetail();
  };

  const showSkeleton = patientCareStore.getCaseLoaded || minAnimation;
  const { userAttributes } = authStore;

  return (
    <div className="dtb-content-wrapper-with-sidebar">
      <Content className="dtb-content ">
        <CaseDetailHeader showSkeleton={showSkeleton} />
        <div
          style={{ background: '#fff', marginBottom: 14 }}
          className="about-wrapper "
        >
          {/*JSON.stringify(medicationTherapyStore.currentMedplanFixed)*/}
          <Tabs
            defaultActiveKey={tab !== null ? tab : 'monitoring'}
            onChange={(key) => {
              navigate(`/patient/${patientId}/case/${caseId}/${key}`);
            }}
            activeKey={tab}
            tabPosition="top"
            animated={{ tabPane: true, inkBar: true }}
          >
            <TabPane tab={t('tabs:patient')} key="patient">
              <Patient />
            </TabPane>
            <TabPane tab={t('tabs:medication')} key="medication">
              <Suspense fallback={<div>Loading...</div>}>
                <Medication />
              </Suspense>
            </TabPane>
            <TabPane tab={t('tabs:monitoring')} key="monitoring">
              {patientCareStore.selectedCase !== null && (
                <>
                  {/*JSON.stringify(medicationTherapyStore?.currentMedplan?.id)*/}
                  <Suspense fallback={<div>Loading...</div>}>
                    <Monitoring />
                  </Suspense>
                </>
              )}
            </TabPane>
            <TabPane
              tab={
                <>
                  {t('tabs:communication')}
                  {messagingStore.getUnreadMessages > 0 && (
                    <Badge
                      className="badge-item"
                      count={messagingStore.getUnreadMessages}
                    />
                  )}
                </>
              }
              key="communication"
            >
              {patientCareStore.selectedCase !== null && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Messaging />
                </Suspense>
              )}
            </TabPane>
          </Tabs>
        </div>
      </Content>

      {!detailInfoVisible && patientId ? (
        <CaseDetailSidebar
          userAttributes={userAttributes}
          showSkeleton={showSkeleton}
        />
      ) : (
        <Drawer
          //title="Multi-level drawer"
          width={280}
          closable={false}
          onClose={onClose}
          visible={detailInfoVisible}
          placement={'right'}
        >
          <CaseDetailSidebar
            userAttributes={userAttributes}
            showSkeleton={showSkeleton}
          />
        </Drawer>
      )}
    </div>
  );
};

export default inject(
  'uiStore',
  'patientCareStore',
  'therapyStore',
  'medicationTherapyStore',
  'authStore',
  'messagingStore'
)(observer(CaseDetail));
